import styled from "styled-components";

export const Container = styled.div`
  background-color: #78c6b3;
  min-height: 100vh;
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  z-index: 10;
  top: min(calc(100vw / 2), calc(100vh - 160px));
`;

export const Navigation = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 30px;
  width: 100vw;
`;

export const LocationLabel = styled.label`
  height: 60px;
  min-width: 260px;
  background-color: #32a67c;
  border-radius: 30px;
  border: 1px solid black;
  display: block;
  line-height: 60px;
  color: white;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;
