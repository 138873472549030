import React, {useState} from "react";
import * as S from "./styles";
import {ReactComponent as AvatarSvg} from "./images/avatar.svg";
import {ReactComponent as WristSvg} from "./images/wrist.svg";
import {ReactComponent as ArmSvg} from "./images/arm.svg";
import {Answer, QuizQuestion} from "../../../dto/questions";
import {Option} from "../../ui/Option/Option";
import {Button} from "../../ui/Button/Button";

interface Props {
    question: QuizQuestion;
    onSubmitted(answer: Answer): void;
}

export const QuizQuestionForm: React.FC<Props> = (props) => {

    const {question, onSubmitted} = props;
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [pointerTopOffset, setPointerTopOffset] = useState<number | null>(null);

    function submit() {
        if (selectedOptions.length === 0) {
            throw Error("An attempt to submit a form without values");
        }
        onSubmitted({values: selectedOptions});
    }

    function toggleOption(option: string) {
        if (question.multipleAnswers) {
            if (selectedOptions.includes(option)) {
                setSelectedOptions(selectedOptions.filter(o => o !== option));
            } else {
                setSelectedOptions([...selectedOptions, option]);
            }
        } else {
            setSelectedOptions([option]);
        }
    }

    function onOptionHover(idx: number) {
        setPointerTopOffset(20 + idx * 64);
    }

    return <div>
        <S.Container>
            <S.AvatarContainer>
                <AvatarSvg style={{height: "300px", margin: "20px", position: "absolute", right: 0}}/>
                <WristSvg style={{height: "40px", position: "absolute", right: "230px"}}/>
                {pointerTopOffset && <ArmSvg style={{height: "28px", position: "absolute", right: "-100px", top: `${pointerTopOffset}px` }}/>}
            </S.AvatarContainer>
            <S.OptionContainer>
                {question.options.map((o, idx) => <Option
                    key={o}
                    active={selectedOptions.includes(o)}
                    onClick={() => toggleOption(o)}
                    onHover={() => onOptionHover(idx)}
                >
                    {o}
                </Option>)}
            </S.OptionContainer>
        </S.Container>
        <div style={{marginTop: "30px", textAlign: "center"}}>
            <Button onClick={submit} disabled={selectedOptions.length === 0} style={{display: "inline-block"}}>
                Submit
            </Button>
        </div>
    </div>;

};
