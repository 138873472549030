import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  background-color: #efefef;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 40px;
  justify-content: center;
  margin-top: 45px;
`;

export const List = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  align-items: center;
  width: 500px;
  text-align: center;
  margin: auto;
`;

export const Score = styled.div<{color: string}>`
  height: 60px;
  border-radius: 30px;
  line-height: 60px;
  border: 2px solid black;
  width: 460px;
  overflow: hidden;
  text-align: center;
  background-color: ${props => props.color};
  margin: auto;
`;

export const SvgContainer = styled.div`
  position: relative;
  flex: 1 0;
  height: 330px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;
