import React, {useState} from "react";
import {Answer, MatchQuestion} from "../../../dto/questions";
import * as S from "./styles";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DraggableElement} from "../../DraggableElement/DraggableElement";
import {Option} from "../../ui/Option/Option";
import {ReactComponent as AvatarSvg} from "./images/avatar.svg";
import {Button} from "../../ui/Button/Button";

interface Props {
    question: MatchQuestion;
    onSubmitted(answer: Answer): void;
}

export const MatchQuestionForm: React.FC<Props> = (props) => {

    const {question, onSubmitted} = props;

    const [orderedRightOptions, setOrderedRightOptions] = useState(question.optionsRight);

    function onMove(fromIndex: number, toIndex: number) {
        const tmpList = [...orderedRightOptions];
        const tmp = tmpList[fromIndex];
        tmpList[fromIndex] = tmpList[toIndex];
        tmpList[toIndex] = tmp;
        setOrderedRightOptions(tmpList);
    }

    function submit() {
        onSubmitted({
            values: question.optionsLeft.map((left, idx) => `${left}:${orderedRightOptions[idx]}`),
        });
    }

    return <div>
        <S.Container>
            <div>
                <AvatarSvg style={{maxHeight: "300px", margin: "20px"}}/>
            </div>
            <S.MatchingContainer>
                <S.LeftButtonsContainer>
                    {question.optionsLeft.map(o => <Option key={o}>
                        {o}
                    </Option>)}
                </S.LeftButtonsContainer>
                <div>
                    <DndProvider backend={HTML5Backend}>
                        {orderedRightOptions.map((o, idx) => <DraggableElement key={o} id={o} index={idx} onMove={onMove}>
                            <Option active={true} margin={2}>
                                {o}
                            </Option>
                        </DraggableElement>)}
                    </DndProvider>
                </div>
            </S.MatchingContainer>
        </S.Container>
        <div style={{textAlign: "center", margin: "20px 0", width: "100%"}}>
            <Button onClick={submit} style={{display: "inline-block"}}>
                Submit
            </Button>
        </div>
    </div>;

};
