import React from "react";
import * as S from "./styles";
import logo from "./images/logo.png";
import homeIcon from "./images/home-icon.png";
import winIcon from "./images/win-icon.png";
import muteIcon from "./images/mute-icon.png";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../dto/pages";

interface Props {
    children?: React.ReactNode;
    score?: number | null;
}

export const Header: React.FC<Props> = (props) => {

    const {children, score} = props;
    const navigate = useNavigate();

    return <S.Container>
        <img src={logo} style={{height: "100%"}}/>
        <div>
            {children}
        </div>
        <div style={{display: "flex", flexFlow: "column nowrap", gap: "4px", justifyContent: "center"}}>
            <S.ButtonsGroup>
                <S.IconButton src={homeIcon} onClick={() => navigate(Pages.HOME)}/>
                <S.IconButton src={winIcon} onClick={() => navigate(Pages.SCOREBOARD)}/>
                <S.IconButton src={muteIcon}/>
            </S.ButtonsGroup>
            {score != null && <span>+{score}pts</span>}
        </div>
    </S.Container>;

};
