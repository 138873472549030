import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {Pages} from "../../dto/pages";
import {HomePage} from "../HomePage/HomePage";
import {ScoreboardPage} from "../ScoreboardPage/ScoreboardPage";
import {PlayPage} from "../PlayPage/PlayPage";
import {ProfilePage} from "../ProfilePage/ProfilePage";
import {PreferencesPage} from "../PreferencesPage/PreferencesPage";
import {Menu} from "../Menu/Menu";
import {LevelPage} from "../LevelPage/LevelPage";
import {Header} from "../Header/Header";
import {WelcomePage} from "../WelcomePage/WelcomePage";

export const AppRoot: React.FC = () => {

    return <div>
        <Routes>
            <Route path={Pages.HOME} element={<HomePage/>}/>
            <Route path={Pages.PLAY} element={<PlayPage/>}/>
            <Route path={Pages.PLAY + "/:levelId"} element={<LevelPage/>}/>
            <Route path={Pages.SCOREBOARD} element={<ScoreboardPage/>}/>
            <Route path={Pages.PROFILE} element={<ProfilePage/>}/>
            <Route path={Pages.PREFERENCES} element={<PreferencesPage/>}/>
            <Route path={Pages.WELCOME} element={<WelcomePage/>}/>
            <Route path="/*" element={<Navigate to={Pages.HOME}/>}/>
        </Routes>
    </div>;

};
