import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  background-color: #ff98c5;
`;

export const MessageBubble = styled.div<{color: string}>`
  background-color: ${props => props.color};
  color: white;
  margin: 60px auto 0;
  max-width: 50vw;
  font-size: 18px;
  border-radius: 32px;
  padding: 32px;
  position: relative;
`;
