import React, {useLayoutEffect, useState} from "react";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";

export const history = createBrowserHistory();

/**
 * A Router wrapper makes possible to use history from outside React-components (e.g. from epics)
 */
export const CustomRouter: React.FC<{basename: string, children?: React.ReactNode;}> = (props) => {

    const {basename, children} = props;
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return <Router
        basename={basename}
        children={children}
        location={state.location}
        navigator={history}
        navigationType={state.action}
    />

};
