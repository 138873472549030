import styled from "styled-components";

export const AvatarContainer = styled.div`
  position: relative;
  flex: 1 0;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 80px;
  justify-content: center;
  margin-top: 40px;
`;

export const OptionContainer = styled.label`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  flex: 1 0;
`;
