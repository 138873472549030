import React from "react";
import styled from "styled-components";
import useFitText from "use-fit-text";

interface Props {
    children: React.ReactNode;
    active?: boolean;
    onClick?: () => void;
    margin?: number;
    onHover?: () => void;
}

const StyledElement = styled.button<{draggable?: boolean, margin?: number}>`
  height: 60px;
  width: 300px;
  background-color: ${props => props.draggable ? "#a039ec" : "#00a67c"};
  color: white;
  border: 1px solid black;
  border-radius: 30px;
  margin: ${props => `${props.margin ?? 0}px`};
  cursor: pointer;
`;

export const Option: React.FC<Props> = (props) => {

    const {children, active, onClick, margin, onHover} = props;
    const {fontSize, ref} = useFitText();

    return <StyledElement
        draggable={active}
        onClick={onClick ? () => onClick() : undefined}
        margin={margin}
        onMouseEnter={onHover ? () => onHover() : undefined}
    >
        <div ref={ref} style={{fontSize}}>
            {children}
        </div>
    </StyledElement>

};
