export abstract class Question {
    protected constructor(
        public readonly text: string,
        public readonly category: string,
        public readonly complexity: number,
        public readonly isLast: boolean,
    ) { }

    static parse(obj: any): Question {
        const type = obj.type;
        const text = obj.text;
        const category = obj.category;
        const complexity = obj.complexity;
        const isLast = obj.isLast;
        switch (type) {
            case "quiz_s": {
                return new QuizQuestion(text, category, complexity, isLast, obj.options, obj.multipleAnswers);
            }
            case "match_s": {
                return new MatchQuestion(text, category, complexity, isLast, obj.optionsLeft, obj.optionsRight);
            }
            default: {
                throw Error(`Unsupported question type: ${type}`);
            }
        }
    }
}

export class QuizQuestion extends Question {
    constructor(
        text: string,
        category: string,
        complexity: number,
        isLast: boolean,
        public readonly options: string[],
        public readonly multipleAnswers: boolean,
    ) {
        super(text, category, complexity, isLast);
    }
}

export class MatchQuestion extends Question {
    constructor(
        text: string,
        category: string,
        complexity: number,
        isLast: boolean,
        public readonly optionsLeft: string[],
        public readonly optionsRight: string[],
    ) {
        super(text, category, complexity, isLast);
    }
}

export interface Answer {
    values: string[];
}

export class AnswerSubmissionResult {
    constructor(
        public readonly correct: boolean,
        public readonly correctOptions: string[],
        public readonly score: number,
        public readonly message?: string | null,
    ) { }

    static parse(obj: any): AnswerSubmissionResult {
        return new AnswerSubmissionResult(
            obj.correct,
            obj.correct_options,
            obj.score,
            obj.message,
        );
    }
}