import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {useNavigate, useParams} from "react-router-dom";
import {Answer, AnswerSubmissionResult, MatchQuestion, Question, QuizQuestion} from "../../dto/questions";
import {API} from "../../services/api";
import {QuizQuestionForm} from "../question-forms/QuizQuestionForm/QuizQuestionForm";
import {MatchQuestionForm} from "../question-forms/MatchQuestionForm/MatchQuestionForm";
import {Header} from "../Header/Header";
import {Button} from "../ui/Button/Button";
import {Pages} from "../../dto/pages";

export const LevelPage: React.FC = () => {

    const params = useParams();
    const navigate = useNavigate();
    const levelId: string = params.levelId!;

    const [question, setQuestion] = useState<Question | null>(null);
    const [submissionResult, setSubmissionResult] = useState<AnswerSubmissionResult | null>(null);
    const [score, setScore] = useState<number | null>(null);

    useEffect(() => {
        loadNextQuestion();
    }, []);

    function loadNextQuestion() {
        if (question?.isLast) {
            navigate(Pages.PLAY);
        } else {
            setQuestion(null);
            setSubmissionResult(null);
            API.getQuestion(levelId).subscribe(setQuestion);
        }
    }

    function onSubmit(answer: Answer) {
        console.log("Submitting answer:", answer);
        API.submitAnswer(levelId, answer).subscribe(result => {
            setSubmissionResult(result);
            setScore(result.score);
        });
    }


    let questionForm = null;

    if (question === null && submissionResult === null) {
        questionForm = <p>Loading question</p>;
    } else if (submissionResult !== null) {
        questionForm = <div>
            <S.AnswerBubble>
                <S.ResultLabel>correct answer</S.ResultLabel>
                {question instanceof QuizQuestion
                    ? <p style={{fontSize: "1.2em", fontWeight: 600, margin: "0 0 30px"}}>{submissionResult.correctOptions.join(", ")}</p>
                    : <div>
                        {submissionResult.correctOptions.map(o => <p key={o}>
                            {o.replace(":", " – ")}
                        </p>)}
                    </div>
                }
                <p>{submissionResult.message}</p>
                <Button onClick={() => loadNextQuestion()} style={{position: "absolute", top: "50%", right: "-200px", transform: "translateY(-50%)"}}>
                    Next
                </Button>
            </S.AnswerBubble>
        </div>;
    } else if (question instanceof QuizQuestion) {
        questionForm = <QuizQuestionForm question={question} onSubmitted={onSubmit}/>
    } else if (question instanceof MatchQuestion) {
        questionForm = <MatchQuestionForm question={question} onSubmitted={onSubmit}/>
    } else {
        console.error("Unsupported question", question);
        throw Error("Unsupported question");
    }

    return <S.Container>
        <Header score={score}>
            <S.Text>
                {question?.text.replace(/:$/, "")}
            </S.Text>
        </Header>
        <div>
            {questionForm}
        </div>
    </S.Container>;

};
