import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Header} from "../Header/Header";
import {Button} from "../ui/Button/Button";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../dto/pages";
import {ReactComponent as HeadSvg} from "./images/head.svg";
import {ReactComponent as HandSvg} from "./images/hand.svg";
import {API} from "../../services/api";

const LS_KEY = "lastUser";

const TEXT1 = "Hey @name, so glad to have you on JetQuest! We're here to learn more about JetBrains locations and its products and to do so we've built 9 levels with interactive questions and challenges.";
const TEXT2 = "You'll have 15 seconds to answer each question. The faster you are, the more points you'll get. Ready? Doesn't matter, let's go!";

export const WelcomePage: React.FC = () => {

    const navigate = useNavigate();
    const [name, setName] = useState<string | null>(localStorage.getItem(LS_KEY));
    const [step, setStep] = useState(0);

    useEffect(() => {
        API.getCurrentUser().subscribe(user => {
            const name = capitalizeFirstLetter(user.email.split("@")[0].split(".")[0]);
            setName(name);
            localStorage.setItem(LS_KEY, name);
        });
    }, []);

    function next() {
        if (step === 0) {
            setStep(1);
        } else {
            navigate(Pages.PLAY);
        }
    }

    return <S.Container>
        <Header>
            <h1 style={{color: "white"}}>Welcome</h1>
        </Header>
        <S.MessageBubble color={step === 0 ? "#a039ec" : "#32a67c"}>
            <p style={{padding: 0, margin: 0}}>
                {step === 0 ? TEXT1.replace("@name", name ?? "@name") : TEXT2}
            </p>
            <Button onClick={next} style={{position: "absolute", top: "50%", right: "-270px", transform: "translateY(-50%)"}}>
                continue
            </Button>
            <HeadSvg style={{position: "absolute", height: "100px", bottom: "-50px", right: "-150px"}}/>
            <HandSvg style={{position: "absolute", height: "140px", left: "-150px", top: "20px"}}/>
        </S.MessageBubble>
    </S.Container>;

};

function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
