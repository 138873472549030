import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const MatchingContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 40px
`;

export const LeftButtonsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
`;
