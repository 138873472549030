import {catchError, from, map, Observable} from "rxjs";
import {Level} from "../dto/level";
import axios from "axios";
import {CONFIG} from "../config";
import {Answer, AnswerSubmissionResult, Question} from "../dto/questions";
import {Scoreboard} from "../dto/scoreboard";
import {GetUserResponse} from "../dto/get-user-response";

export namespace API {

    export function getAvailableLevels(): Observable<Level[]> {
        return from(axios.get(`${CONFIG.host}/levels`, {withCredentials: true})).pipe(
            map(response => response.data),
            catchError(e => {
                if (e.response.status === 401 || e.response.status === 403) {
                    window.location.href = `${CONFIG.host}/oauth/login/google`;
                }
                throw Error();
            }),
        );
    }

    export function getQuestion(levelId: string): Observable<Question> {
        return from(axios.get(`${CONFIG.host}/levels/${levelId}/next`, {withCredentials: true})).pipe(
            map(response => Question.parse(response.data)),
            catchError(e => {
                if (e.response.status === 401 || e.response.status === 403) {
                    window.location.href = `${CONFIG.host}/oauth/login/google`;
                }
                throw Error();
            }),
        );
    }

    export function submitAnswer(levelId: string, answer: Answer): Observable<AnswerSubmissionResult> {
        return from(axios.post(`${CONFIG.host}/levels/${levelId}/submit`, answer, {withCredentials: true})).pipe(
            map(response => AnswerSubmissionResult.parse(response.data)),
            catchError(e => {
                if (e.response.status === 401 || e.response.status === 403) {
                    window.location.href = `${CONFIG.host}/oauth/login/google`;
                }
                throw Error();
            }),
        );
    }

    export function getScoreboard(): Observable<Scoreboard> {
        return from(axios.get(`${CONFIG.host}/highscores`, {withCredentials: true})).pipe(
            map(response => response.data),
            catchError(e => {
                if (e.response.status === 401 || e.response.status === 403) {
                    window.location.href = `${CONFIG.host}/oauth/login/google`;
                }
                throw Error();
            }),
        );
    }

    export function getCurrentUser(): Observable<GetUserResponse> {
        return from(axios.get(`${CONFIG.host}/users/me`, {withCredentials: true})).pipe(
            map(response => response.data),
            catchError(e => {
                if (e.response.status === 401 || e.response.status === 403) {
                    window.location.href = `${CONFIG.host}/oauth/login/google`;
                }
                throw Error();
            }),
        );
    }

}
