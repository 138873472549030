import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Scoreboard} from "../../dto/scoreboard";
import {API} from "../../services/api";
import {Header} from "../Header/Header";
import {ReactComponent as CrownSvg} from "./images/crown.svg";
import {ReactComponent as HeadSvg} from "./images/head.svg";
import {STYLING} from "../../styling";
import {Button} from "../ui/Button/Button";

type SortingType = "score" | "levels" | "questions" | "mistakes";

export const ScoreboardPage: React.FC = () => {

    const [scoreboard, setScoreboard] = useState<null | Scoreboard>(null);
    const [sorting, setSorting] = useState<SortingType>("score");
    const sortedScores = scoreboard?.scores.sort((a, b) => {
        if (sorting === "score") {
            return b.scores.timeBasedScore - a.scores.timeBasedScore;
        } else if (sorting === "levels") {
            return b.scores.completedLevels - a.scores.completedLevels;
        } else if (sorting === "questions") {
            return b.scores.completedQuestions - a.scores.completedQuestions;
        } else {
            return a.scores.wrongReplies - b.scores.wrongReplies;
        }
    }) ?? null;

    useEffect(() => {
        API.getScoreboard().subscribe(setScoreboard);
    }, []);

    return <S.Container>
        <Header>
            <h1 style={{margin: 0, fontSize: "32px"}}>Scoreboard</h1>
        </Header>
        <S.Content>
            <div style={{flex: "1 0"}}>
                {sortedScores === null
                    ? <p>Loading...</p>
                    : <S.List>
                        {sortedScores.map((score, idx) => {
                            let value = score.scores.timeBasedScore;
                            if (sorting === "mistakes") {
                                value = score.scores.wrongReplies;
                            } else if (sorting === "levels") {
                                value = score.scores.completedLevels;
                            } else if (sorting === "questions") {
                                value = score.scores.completedQuestions;
                            }
                            return <S.Score color={resolveColor(idx + 1)} key={score.userEmail}>
                                #{idx + 1}. {score.userEmail.toUpperCase()} – {value}
                            </S.Score>;
                        })}
                    </S.List>
                }
            </div>
            <div style={{flex: "1 0", position: "relative"}}>
                <S.SvgContainer>
                    <HeadSvg style={{position: "absolute", margin: "auto", inset: "0"}}/>
                    <CrownSvg style={{position: "absolute", margin: "auto", inset: "-240px 0 0 0"}}/>
                </S.SvgContainer>
                <S.ButtonsContainer>
                    <Button onClick={() => setSorting("score")} style={{textDecoration: sorting === "score" ? "underline" : "initial"}}>score</Button>
                    <Button onClick={() => setSorting("levels")} style={{textDecoration: sorting === "levels" ? "underline" : "initial"}}>levels</Button>
                    <Button onClick={() => setSorting("questions")} style={{textDecoration: sorting === "questions" ? "underline" : "initial"}}>questions</Button>
                    <Button onClick={() => setSorting("mistakes")} style={{textDecoration: sorting === "mistakes" ? "underline" : "initial"}}>mistakes</Button>
                </S.ButtonsContainer>
            </div>
        </S.Content>
    </S.Container>;

};

function resolveColor(idx: number): string {
    if (idx === 1) {
        return "#fcf849";
    } else if (idx < 4) {
        return "#a039ec";
    } else {
        return STYLING.colors.magenta;
    }
}
