import styled from "styled-components";

export const Header = styled.h1`
  background-color: #00a67c;
  color: white;
  padding: 12px 48px;
  border-radius: 26px;
`;

export const Container = styled.div`
  background-color: #efefef;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const AvatarContainer = styled.div`
  position: absolute;
  margin: auto;
  inset: 60px 0 0 0;
  width: 210px;
`;
