import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Level, LevelStatus} from "../../dto/level";
import {API} from "../../services/api";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../dto/pages";
import {Header} from "../Header/Header";
import {ReactComponent as MapSvg} from "./images/map.svg";
import {ReactComponent as TriangleSvg} from "./images/triangle.svg";
import {ReactComponent as PinSvg} from "./images/pin.svg";
import {Button} from "../ui/Button/Button";

type Coordinates = {left: number, top: number};

const LS_KEY = "lastStartedLevel";
export const PlayPage: React.FC = () => {

    const navigate = useNavigate();
    const [selectedLevel, setSelectedLevel] = useState<string | null>(null);
    const [levels, setLevels] = useState<Level[] | null>(null);
    const currentLevelStatus = levels?.find(l => l.id === selectedLevel)?.status ?? null;
    const pinCoordinates = resolvePinCoordinates(levels !== null && selectedLevel !== null && levels.find(l => l.id === selectedLevel)!.id || null);

    useEffect(() => {
        loadLevels();
    }, []);

    function loadLevels() {
        API.getAvailableLevels().subscribe(levels => {
            setLevels(levels);
            setSelectedLevel(localStorage.getItem(LS_KEY) ?? levels[0].id);
        });
    }

    function start() {
        localStorage.setItem(LS_KEY, selectedLevel!);
        navigate(Pages.PLAY + "/" + selectedLevel);
    }

    function moveBack() {
        if (levels === null) {
            return;
        }
        const currentIndex = levels.findIndex(l => l.id === selectedLevel!);
        const newIndex = currentIndex === 0 ? (levels.length - 1) : currentIndex - 1;
        setSelectedLevel(levels[newIndex].id);
    }

    function moveNext() {
        if (levels === null) {
            return;
        }
        const currentIndex = levels.findIndex(l => l.id === selectedLevel!);
        const newIndex = currentIndex === (levels.length - 1) ? 0 : currentIndex + 1;
        setSelectedLevel(levels[newIndex].id);
    }

    return <S.Container>
        <Header>
            <h1>Map</h1>
        </Header>
        <MapSvg style={{position: "absolute", top: 0, left: 0, width: "100vw", height: "50vw"}}/>
        {pinCoordinates && <PinSvg style={{position: "absolute", top: `calc(${pinCoordinates.top} * 100vw - 58.25px)`, left: `calc(${pinCoordinates.left} * 100vw - 30.51px)`}}/>}
        <S.Content>
            <div style={{textAlign: "center", margin: "20px"}}>
                <Button onClick={start} style={{minWidth: "260px"}} disabled={currentLevelStatus !== "STARTED" && currentLevelStatus !== "UNLOCKED"}>
                    {currentLevelStatus !== null && resolveButtonTest(currentLevelStatus) || "loading..."}
                </Button>
            </div>
            <S.Navigation>
                <TriangleSvg onClick={moveBack} style={{height: "60px", cursor: "pointer", transform: "rotate(180deg)"}}/>
                <S.LocationLabel>{selectedLevel}</S.LocationLabel>
                <TriangleSvg onClick={moveNext} style={{height: "60px", cursor: "pointer"}}/>
            </S.Navigation>
        </S.Content>
    </S.Container>;

};

function resolveButtonTest(status: LevelStatus): string {
    switch (status) {
        case "LOCKED": return "locked";
        case "STARTED": return "continue";
        case "UNLOCKED": return "start";
        case "COMPLETED": return "completed";
        default: throw Error("Unknown level status: " + status);
    }
}

function resolvePinCoordinates(levelId: string | null): Coordinates | null {
    switch (levelId) {
        case "PRG": return {left: 0.085, top: 0.215};
        case "BOS": return {left: 0.085, top: 0.345};
        case "MUC": return {left: 0.308, top: 0.185};
        case "AMS": return {left: 0.336, top: 0.347};
        case "PVG": return {left: 0.564, top: 0.185};
        case "BEG": return {left: 0.604, top: 0.310};
        case "EVN": return {left: 0.858, top: 0.199};
        case "LCA": return {left: 0.891, top: 0.330};
        case "BER": return {left: 0.845, top: 0.390};
        default: return null;
    }
}
