import React from "react";
import * as S from "./styles";
import {Header} from "../Header/Header";
import {ReactComponent as RoutesSvg} from "./images/routes.svg";
import {ReactComponent as DotsSvg} from "./images/dots.svg";
import {ReactComponent as BodySvg} from "./images/body.svg";
import {ReactComponent as HeadSvg} from "./images/head.svg";
import {ReactComponent as Arm1Svg} from "./images/arm1.svg";
import {ReactComponent as Arm2Svg} from "./images/arm2.svg";
import {ReactComponent as Arm3Svg} from "./images/arm3.svg";
import {ReactComponent as Arm4Svg} from "./images/arm4.svg";
import {useNavigate} from "react-router-dom";
import {Pages} from "../../dto/pages";
import {Button} from "../ui/Button/Button";

export const HomePage: React.FC = () => {

    const navigate = useNavigate();

    return <S.Container>
        <Header>
            <S.Header>
                JetQuest
            </S.Header>
        </Header>
        <RoutesSvg style={{width: '100%', height: 'auto', position: 'absolute', top: 0, right: 0}}/>
        <DotsSvg style={{width: '100%', height: 'auto', position: 'absolute', top: 0, right: 0}}/>
        <div style={{position: "relative", zIndex: 10}}>
            <div style={{margin: "100px", display: "flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
                <Button onClick={() => navigate(Pages.WELCOME)}>start</Button>
                <Button onClick={() => navigate(Pages.PLAY)}>map</Button>
            </div>
            <div style={{margin: "72px", display: "flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
                <Button onClick={() => navigate(Pages.SCOREBOARD)}>scores</Button>
                <Button onClick={() => navigate(Pages.PREFERENCES)} disabled={true}>settings</Button>
            </div>
            <S.AvatarContainer>
                <BodySvg/>
                <HeadSvg className="pulse" style={{position: "absolute", top: "-60px", left: "80px"}}/>
                <Arm1Svg className="pulse" style={{position: "absolute", top: "-130px", left: "-100px"}}/>
                <Arm2Svg className="pulse" style={{position: "absolute", top: "90px", left: "-240px"}}/>
                <Arm3Svg className="pulse" style={{position: "absolute", top: "0px", left: "230px"}}/>
                <Arm4Svg className="pulse" style={{position: "absolute", top: "90px", left: "200px"}}/>
            </S.AvatarContainer>
        </div>
    </S.Container>;

};
