import React, {ButtonHTMLAttributes} from "react";
import {Button as StyledButton} from "./styles";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    onClick(): void;
}

export const Button: React.FC<Props> = (props) => {

    const {children, onClick, style, disabled} = props;

    return <StyledButton onClick={onClick} style={style} disabled={disabled}>
        {children}
    </StyledButton>

};
