import styled from "styled-components";
import {STYLING} from "../../styling";

export const Container = styled.nav`
  height: 100px;
  background-color: ${STYLING.colors.magenta};
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
  padding: 0 25px 0 6px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;
  color: white;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
`;

export const IconButton = styled.img`
  height: 40px;
  cursor: pointer;
`;
