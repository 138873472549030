import styled from "styled-components";
import {STYLING} from "../../styling";

export const Container = styled.div`
  min-height: 100vh;
  background-color: #cf9cf5;
`;

export const NextButton = styled.button`
  
`;

export const Text = styled.p`
  margin: 0;
  color: white;
`;

export const AnswerBubble = styled.div`
  background-color: #00a67c;
  color: white;
  margin: 60px auto 0;
  max-width: 50vw;
  font-size: 18px;
  border-radius: 32px;
  padding: 32px;
  position: relative;
  white-space: break-spaces;
  border: 2px solid black;
`;

export const ResultLabel = styled.p`
  font-size: 1.2em;
  color: ${STYLING.colors.magenta};
  font-weight: 600;
  margin: 0;
`;
