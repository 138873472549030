import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import type { Identifier, XYCoord } from 'dnd-core';
import * as S from "./styles";

/**
 * Copy&Paste disclaimer:
 * This component is just a bit modified example from official react-dnd documentation:
 * https://react-dnd.github.io/react-dnd/examples/sortable/simple
 */

interface Props {
    children?: React.ReactNode;
    id: string;
    index: number;
    onMove: (dragIndex: number, hoverIndex: number) => void;
}

const ITEM_TYPE = "SOME_TYPE";

interface DragItem {
    index: number
    id: string
    type: string
}

export const DraggableElement: React.FC<Props> = props => {

    const {children, id, index, onMove} = props;

    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ITEM_TYPE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: () => {
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        <S.DraggableElementContainer ref={ref} style={{opacity}} data-handler-id={handlerId}>
            {children}
        </S.DraggableElementContainer>
    );

};
