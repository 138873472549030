import styled from "styled-components";

export const Button = styled.button`
  height: 56px;
  border-radius: 28px;
  font-size: 20px;
  line-height: 34px;
  padding: 0 28px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  &:disabled {
    color: gray;
    cursor: initial;
  }
`;
